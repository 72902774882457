"use client";
import { Swiper, SwiperSlide } from "swiper/react";

import Image from "next/image";
import { Autoplay } from "swiper/modules";

import "swiper/css";

type Props = {
	images: string[];
};

const Visual = ({ images }: Props) => {
	return (
		<Swiper
			modules={[Autoplay]}
			spaceBetween={0}
			slidesPerView={"auto"}
			loop={true}
			speed={1000}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
		>
			{images.map((src: string, index: number) => (
				<SwiperSlide key={src}>
					<Image
						src={src}
						alt={`visual${index}`}
						className="w-full"
						width={1280}
						height={520}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default Visual;
